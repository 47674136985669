/* eslint-disable no-dupe-keys */
import * as serialisations from '@/constants/serializer';
import * as comparators from '@/constants/comparators';
import { ENUM, LIST } from '@/constants/viewTypes';

import * as schemaItemTypes from './schemaItemTypes';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';
import * as onUpdateEffects from './onUpdateEffects';

const linkedProperties = {
  VAT: 'vat',
  AMOUNT: 'amount',
  PROJECT: 'project',
  BUILDING: 'building',
  UNIT: 'unit',
  IS_ONE_TIME: 'isOneTime',
  PERIOD_TYPE: 'periodType',
  PERIOD: 'period',
  CLIENT: 'client',
  SERVICES: 'services',
};

/** @type {import('./schema').default}  */
export const schema = [
  {
    type: schemaItemTypes.STRING,
    label: 'invoice.invoice_number',
    prop: 'invoiceNumber',
    rules: [rules.REQUIRED],
    payload: [
      {
        from: ['automaticInvoiceNumberingTooltip'],
        param: 'tooltip',
      },
      {
        from: ['automaticInvoiceNumbering'],
        param: 'disabled',
      },
    ],
  },
  {
    type: schemaItemTypes.DATE,
    label: 'invoice.date_invoice',
    prop: 'invoiceDate',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.CLIENT,
    label: 'invoice.select_client',
    prop: linkedProperties.CLIENT,
    rules: [rules.REQUIRED],
    payload: [
      { param: 'projectId', from: [linkedProperties.PROJECT, 'id'] },
      { param: 'clientId', from: [linkedProperties.CLIENT, 'id'] },
      { param: 'unitId', from: [linkedProperties.UNIT, 'id'] },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: linkedProperties.PROJECT,
      },
    ],
  },
  {
    type: schemaItemTypes.PROJECT,
    label: 'invoice.select_project',
    prop: linkedProperties.PROJECT,
    rules: [rules.REQUIRED],
    payload: [{ param: 'clientId', from: [linkedProperties.CLIENT, 'id'] }],
    onUpdate: [
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: linkedProperties.BUILDING,
      },
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: linkedProperties.CLIENT,
      },
    ],
  },
  {
    type: schemaItemTypes.BUILDING,
    label: 'invoice.select_building',
    prop: 'building',
    rules: [rules.REQUIRED],
    payload: [
      { param: 'projectId', from: [linkedProperties.PROJECT, 'id'] },
      { param: 'clientId', from: [linkedProperties.CLIENT, 'id'] },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.SET,
        to: linkedProperties.PROJECT,
        from: 'project',
      },
      {
        type: onUpdateEffects.RESET_OR_SET_IF_ONE,
        to: linkedProperties.UNIT,
      },
    ],
  },
  {
    type: schemaItemTypes.UNIT,
    label: 'invoice.select_unit',
    prop: 'unit',
    rules: [rules.REQUIRED],
    payload: [
      { param: 'buildingId', from: [linkedProperties.BUILDING, 'id'] },
      { param: 'projectId', from: [linkedProperties.PROJECT, 'id'] },
      { param: 'clientId', from: [linkedProperties.CLIENT, 'id'] },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.SET,
        to: linkedProperties.BUILDING,
        from: 'building',
      },
    ],
  },
  {
    type: schemaItemTypes.CONTRACTOR,
    label: 'invoice.select_contractor',
    prop: 'contractor',
    rules: [rules.REQUIRED],
  },
  {
    label: 'invoice.vat_code',
    prop: 'vatCode',
    type: schemaItemTypes.STRING,
  },
  {
    label: 'invoice.department',
    prop: 'department',
    type: schemaItemTypes.STRING,
  },
  {
    type: schemaItemTypes.ENUM,
    label: 'invoice.service_type',
    prop: 'serviceType',
    dictionary: dictionaryTypes.SERVICE_TYPES,
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.LIST,
    prop: linkedProperties.SERVICES,
    uniqueKey: 'id',
    label: 'invoice.add_service_title',
    addButtonText: 'invoice.add_service',
    min: 1,
    viewType: LIST.counter,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'invoice.write_service',
        prop: 'service',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.NUMBER,
            label: 'invoice.write_amount',
            prop: linkedProperties.AMOUNT,
            rules: [rules.REQUIRED, rules.POSITIVE],
          },
          {
            type: schemaItemTypes.PERCENT,
            label: 'invoice.vat',
            prop: linkedProperties.VAT,
            rules: [rules.REQUIRED, rules.PERCENT],
          },
        ],
      },
    ],
  },

  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'invoice.invoice_period',
  },
  {
    type: schemaItemTypes.ENUM,
    viewType: ENUM.radio,
    prop: linkedProperties.PERIOD_TYPE,
    dictionary: dictionaryTypes.PERIOD_TYPES,
    defaultValue: 'day',
  },
  {
    type: schemaItemTypes.DATE,
    label: 'invoice.date',
    prop: 'period',
    payload: [{ param: 'type', from: [linkedProperties.PERIOD_TYPE], serializer: serialisations.PERIOD }],
    rules: [rules.REQUIRED],
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.PERIOD_TYPE],
          comparableValues: ['day', 'month'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.PERIOD_TYPE}`,
    },
  },
  {
    type: schemaItemTypes.DATE,
    label: 'invoice.date_custom',
    prop: 'customPeriod',
    type: 'date',
    multiple: true,
    rules: [rules.REQUIRED],
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.PERIOD_TYPE],
          comparableValues: ['period'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.PERIOD_TYPE}`,
    },
  },
  {
    type: schemaItemTypes.TOTAL_AMOUNT,
    label: 'invoice.total_amount',
    payload: [
      {
        param: 'services',
        from: [linkedProperties.SERVICES],
      },
      {
        param: 'currencySymbol',
        from: [linkedProperties.PROJECT, 'currency', 'symbol'],
      },
    ],
  },
];

export { schema as default };
